import React from 'react'
import Layout from '../components/layout'
import services_hero from '../assets/illustrations/stylish/website-designing-1768780-0.svg';
import { Link } from 'gatsby';
import services from '../data/services.json';
import services_list from '../data/services_list.json';
import Seo from '../components/seo';

export default function MHDPrivacyPolicy() {
    return (
        <Layout>
            <Seo title="Privacy Policy" />
            <div className="bg-white py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="head-font font-bold text-3xl 4xl:text-4xl text-darkblue-tf">MAKATI HEALTH DEPARTMENT</h1>
                        <h1 className="text-md 4xl:text-2xl font-thin text-gray-700 uppercase">PATIENT CONSENT FORM</h1>
                        {/* <p className="text-md font-thin text-gray-700">QAPPS & QMOBILE</p> */}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="font-bold 4xl:text-2xl text-lg text-darkblue-tf">
                            To be able to proceed with your Medical Consult / Examination and in Compliance with the Data Privacy Act of 2012, Kindly Signify and state your expressed consent by affixing your Signature below this form to enable our team to do the following</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2 ml-3">
                            • Perform your Medical test and physical examination if necessary.</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2 ml-3">
                            • Take your Medical History</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2 ml-3">
                            • Access, Record Collect, analyze, interpret, and disclose your diagnostic test Result and Physical Examination findings</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2 ml-3">
                            • Accomplish your fitness / Medical Certification</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2 ml-3">
                            • Participate in Customer Satisfaction Survey</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            For your Reference and Guidance, Please Read the back page here of for the Data Privacy Information. If you wish to obtain a better understanding of the Data Privacy Act, you may refer to our data privacy manual.</p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="text-3xl 4xl:text-4xl head-font font-bold  text-darkblue-tf">PRIVACY POLICY</h1>
                        {/* <p className="text-md 4xl:text-2xl font-thin text-gray-700 uppercase">Frequently asked questions</p> */}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="font-bold 4xl:text-2xl text-lg text-darkblue-tf">
                            In compliance with the Data Privacy Act (DPA) of 2012, and its Implementing Rules and Regulations (IRR) effective since September 8, 2016,</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            I allow the <strong>Makati Health Department of the City Government of Makati and its Health Facilities</strong> to provide me certain services in relation to my Medical Consult / Examination above listed.</p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            As such, I agree and authorize them to:</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • Collect and use my personal information and sensitive personal information for the purpose stated above and for whatever legal purpose/s it may be intended for.</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • Process, retain and store my information for a certain period of time as prescribed by law from the date of the accomplishment of the purpose stated above. I agree that my information will be deleted/destroyed after this period.</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • Share my information to other office/department within the City Government of Makati, Ospital ng Makati and necessary third parties for any legitimate purpose. I am assured that security systems are employed to protect my information. </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • I alone can view, change and recover the personal information I shared unless I authorize a representative on my behalf armed with a Special Power of Attorney duly notarized for this purpose. This applies also to any request for a certified true copy bearing any of my personal information.</p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • Inform me of future services or projects offered by the City Government of Makati using the personal information I shared. </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            •  I allow the city to send me a Customer Satisfaction Survey form of the services provided above. Under no circumstance that I will be obligated to participate in the survey, and I retain the right to refuse. </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-2">
                            • I hold free and harmless and indemnify the City Government of Makati, any of its offices/departments, officers, employees and agents from any complaint, suit, or damages which any party may file or claim in relation to the Data Privacy Act.</p>


                    </div>
                </div>
            </div>


        </Layout>
    )
}
